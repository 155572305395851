<template>
  <b-card
    v-if="canShowSection()"
    id="online-presence-section"
    no-body
  >
    <b-card-header
      v-b-toggle.verify-order-accordion-6
      :class="{ disabled: sectionIsDisabled('business_identity') }"
      header-tag="header"
      class="accordion-header"
      role="tab"
      @click.stop.capture="$emit('set-as-current')"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="header-left">
          <h4>
            {{ content.cardHeader.title }}
          </h4>
          <h5 v-show="sectionIsComplete('business_identity')">
            <check-circle class="check-circle" width="20" height="20" />
            <span>
              {{ addOnBizIdHeaderText }}
            </span>
          </h5>
        </div>
        <feather-icon type="chevron-right" />
      </div>
    </b-card-header>
    <b-collapse
      id="verify-order-accordion-6"
      ref="verify-order-accordion-6"
      accordion="verify-order-accordion"
      role="tabpanel"
      :visible="currentSection === 'business_identity'"
    >
      <b-card-body class="card-body">
        <product-pitch-version-b
          :biz-id-image="bizIdImage"
          :content="content"
        />
      </b-card-body>
      <b-card-footer
        footer-tag="footer"
        class="accordion-footer"
        role="tab"
      >
        <b-button
          variant="outline-primary"
          class="standard-verify-button-size"
          aria-label="no thanks button"
          :disabled="addingOrRemovingBundleToCart"
          @click="declineBusinessIdentityBundle()"
        >
          <template v-if="!removingBundleFromCart">
            {{ hasBusinessIdentityInCart ? 'Remove Bundle' : 'No Thanks' }}
          </template>
          <b-spinner v-else small />
        </b-button>
        <b-button
          class="upsell-button standard-verify-button-size button-upgrade"
          variant="primary"
          aria-label="add bundle button"
          :disabled="addingOrRemovingBundleToCart"
          @click="addBusinessIdentityBundleAndProceed()"
        >
          <template v-if="!addingBundleToCart">
            {{ hasBusinessIdentityInCart ? 'Continue' : 'Add Bundle' }}
          </template>
          <b-spinner v-else small />
        </b-button>
      </b-card-footer>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BusinessIdentitySection',
  components:  {
    CheckCircle:  () => import('@images/ui/check-circle.svg'),
    FeatherIcon:  () => import('@/components/shared/FeatherIcon'),
    ProductPitchVersionB: () => import('@/components/VerifyOrder/sections/BusinessIdentity/ProductPitchVersionB'),
  },
  data() {
    return {
      addingBundleToCart: false,
      removingBundleFromCart: false,
      businessIdentityProductBundle: {},
      bizIdImage: require(`@images/illustrations/products/online_presence.png`),
      content: {
        cardHeader: {
          title: "Online Presence",
        },
        body: {
          title: "Everything you need to bring your business online.",
          topDescription: "Our Business Identity Bundle has everything you need to get started online:",
          bottomDescription: "",
        },
        image: {
          displayName: "Business ID Bundle",
        },
        domainInfo: {
          title: "Domain name - ",
          description: "Free for one year",
        },
        websiteInfo: {
          title: "Pre-built website - ",
          description: "Hosted by us, owned by you",
        },
        sslInfo: {
          title: "SSL Certificate - ",
          description: "For your website security",
        },
        emailInfo: {
          title: "Email - ",
          description: "Up to 10 addresses on a shared domain",
        },
        phoneServiceInfo: {
          title: "Business Phone Service - ",
          description: "With local phone number & app",
        },
      },
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'currentBundleItems',
    ]),
    ...mapGetters('companies', [
      'currentCompany',
      'hasProductBundleWithCompanyOrInCartByProductCategory',
    ]),
    ...mapGetters('verifyOrder', [
      'sectionIsComplete',
      'sectionIsDisabled',
      'currentSection',
      'showSection',
      'verifyOrderInteractionVersion',
    ]),
    addingOrRemovingBundleToCart() {
      return this.addingBundleToCart || this.removingBundleFromCart
    },
    addOnBizIdHeaderText() {
      return this.hasBusinessIdentityInCart ?
        `${this.businessIdentityProductBundle.name} Bundle Added`:
        'Not Selected'
    },
    hasBusinessIdentityInCart() {
      const productCategoryName = this.businessIdentityProductBundle?.product_categorization?.subcategory
      return !!this.hasProductBundleWithCompanyOrInCartByProductCategory(productCategoryName)
    },
  },
  async mounted() {
    if (!this.canShowSection()) return
    await this.loadBusinessIdentityProductBundle()
  },
  methods: {
    ...mapActions('checkout', [
      'addToCart',
      'fetchProduct',
      'addBundleToCart',
      'checkForBundleFreeTrialsAndCalculateTotal',
      'removeProductIdFromCart',
    ]),
    ...mapActions('verifyOrder', [
      'logVerifyOrderInteraction',
    ]),
    ...mapActions('freeTrials', [
      'determineTrialEligibilityBatch',
    ]),
    canShowSection() {
      // Need Domestic Jurisdiction to load the bundle product
      return this.showSection('business_identity') &&
        this.currentCompany?.domestic_registration?.jurisdiction_id
    },
    async addBusinessIdentityBundleAndProceed() {
      let interactionName = 'add-business-identity-bundle'
      if (this.hasBusinessIdentityInCart) {
        interactionName = 'business-identity-bundle-continue'
      } else {
        this.addingBundleToCart = true
        const addProductBundle = await this.checkForBundleFreeTrialsAndCalculateTotal({ productBundle: this.businessIdentityProductBundle })
        await this.addBundleToCart({ bundle: { ...addProductBundle, data: this.currentBundleItems } })
        this.addingBundleToCart = false
      }
      await this.logVerifyOrderInteraction(interactionName)
      this.completeSection()
    },
    async declineBusinessIdentityBundle() {
      let interactionName = 'decline-business-identity-bundle'

      if (this.hasBusinessIdentityInCart) {
        this.removingBundleFromCart = true
        interactionName = 'remove-business-identity-bundle'
        await this.removeProductIdFromCart(this.businessIdentityProductBundle.id)
      }
      await this.logVerifyOrderInteraction(interactionName)
      this.removingBundleFromCart = false
      this.completeSection()
    },
    completeSection() {
      this.$emit('completed')
    },
    async loadBusinessIdentityProductBundle() {
      const response = await this.fetchProduct({
        productKind: 'product_bundle',
        productCategory: 'business_identity',
        jurisdictionId: this.currentCompany.domestic_registration.jurisdiction_id,
      })

      this.businessIdentityProductBundle = response.length ? response[0] : {}
    },
  },
}
</script>

<style lang="scss" scoped>
#online-presence-section {
  .card-body {
    margin-bottom: 1em;

    .card-body-title {
      text-align: left;
      margin: 0;
      padding: 0;
      font-weight: 800;
    }
    .text-and-image-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .card-body-text {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;

        .top-description, .bottom-description{
          max-width: 28em;
          text-align: left;

          span {
            font-weight: bold;
          }
        }

        .product-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 0;
          padding-left: 2em;
        }
      }
      img {
        height: 12.5em;
        margin-top: 1em;
      }
    }
  }
}
</style>
