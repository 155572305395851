var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canShowSection()
    ? _c(
        "b-card",
        { attrs: { id: "online-presence-section", "no-body": "" } },
        [
          _c(
            "b-card-header",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.verify-order-accordion-6",
                  modifiers: { "verify-order-accordion-6": true },
                },
              ],
              staticClass: "accordion-header",
              class: { disabled: _vm.sectionIsDisabled("business_identity") },
              attrs: { "header-tag": "header", role: "tab" },
              on: {
                "!click": function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("set-as-current")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("div", { staticClass: "header-left" }, [
                    _c("h4", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.content.cardHeader.title) +
                          "\n        "
                      ),
                    ]),
                    _c(
                      "h5",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sectionIsComplete("business_identity"),
                            expression:
                              "sectionIsComplete('business_identity')",
                          },
                        ],
                      },
                      [
                        _c("check-circle", {
                          staticClass: "check-circle",
                          attrs: { width: "20", height: "20" },
                        }),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.addOnBizIdHeaderText) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("feather-icon", { attrs: { type: "chevron-right" } }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              ref: "verify-order-accordion-6",
              attrs: {
                id: "verify-order-accordion-6",
                accordion: "verify-order-accordion",
                role: "tabpanel",
                visible: _vm.currentSection === "business_identity",
              },
            },
            [
              _c(
                "b-card-body",
                { staticClass: "card-body" },
                [
                  _c("product-pitch-version-b", {
                    attrs: {
                      "biz-id-image": _vm.bizIdImage,
                      content: _vm.content,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-card-footer",
                {
                  staticClass: "accordion-footer",
                  attrs: { "footer-tag": "footer", role: "tab" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "standard-verify-button-size",
                      attrs: {
                        variant: "outline-primary",
                        "aria-label": "no thanks button",
                        disabled: _vm.addingOrRemovingBundleToCart,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.declineBusinessIdentityBundle()
                        },
                      },
                    },
                    [
                      !_vm.removingBundleFromCart
                        ? [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.hasBusinessIdentityInCart
                                    ? "Remove Bundle"
                                    : "No Thanks"
                                ) +
                                "\n        "
                            ),
                          ]
                        : _c("b-spinner", { attrs: { small: "" } }),
                    ],
                    2
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "upsell-button standard-verify-button-size button-upgrade",
                      attrs: {
                        variant: "primary",
                        "aria-label": "add bundle button",
                        disabled: _vm.addingOrRemovingBundleToCart,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addBusinessIdentityBundleAndProceed()
                        },
                      },
                    },
                    [
                      !_vm.addingBundleToCart
                        ? [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.hasBusinessIdentityInCart
                                    ? "Continue"
                                    : "Add Bundle"
                                ) +
                                "\n        "
                            ),
                          ]
                        : _c("b-spinner", { attrs: { small: "" } }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }